import axios from "axios";

let user = JSON.parse(sessionStorage.getItem("user"));
// export let ECOMMERCE_API = `https://ecommerce.vodoglobal.com`
// export let VODOGLOBAL_ACOUNTING_API = `https://accounting.vodoglobal.com`

export let ECOMMERCE_API = `https://testecommerce.vodoglobal.com`
export let VODOGLOBAL_ACOUNTING_API = `https://test.vodoglobal.com`
export const Listprodect = (data) => {

    return async (dispatch) => {

        const res = await axios.post(`${ECOMMERCE_API}/api/POSProductsList?page=all&category_id=all`, data, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'secure-business-key': user && user.data.business_secure_key
            }

        }).then((response) => {


            return response.data
        })
        dispatch({ type: "ALLPRODECTS", prodects: res });
    }
}
export const add_product = (data) => {

    return async (dispatch) => {

        const res = await axios.post(`${ECOMMERCE_API}/api/pos/add_product`, data, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'secure-business-key': user && user.data.business_secure_key,
                'Content-Type': 'multipart/form-data'

            }

        })
        dispatch({ type: "add_product", add_product: res.data });
    }
}
// /////////////////////

export const ListprodectByCatigory = (data) => {

    return async (dispatch) => {

        const res = await axios.post(`${ECOMMERCE_API}/api/POSProductsList`, data, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'secure-business-key': user && user.data.business_secure_key

            }

        }).then((response) => {
            return response.data
        })
        dispatch({ type: "ListprodectByCatigory", ListprodectByCatigory: res });
    }
}
export const ListprodectByBusinessId = (data) => {

    return async (dispatch) => {

        const res = await axios.post(`${ECOMMERCE_API}/api/AllPOSProductsList`, data, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'secure-business-key': user && user.data.business_secure_key

            }
        }).then((response) => {
            return response.data
        })
        dispatch({ type: "ListprodectByCatigory", ListprodectByCatigory: res });
    }
}
// ////////////
export const business_taxes_list = () => {

    return async (dispatch) => {

        const res = await axios.post(`${VODOGLOBAL_ACOUNTING_API}/api/pos/business_taxes_list`, {}, {
            headers: {
                'apikey': 'efe2db322a53',
                'secure-business-key': user && user.data.business_secure_key,
                'apiconnection': 'appmobile',

            }
        }).then((response) => {
            return response.data
        })
        dispatch({ type: "business_taxes_list", business_taxes_list: res });
    }
}
export const products_brands = () => {

    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_API}/api/pos/products_brands`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'secure-business-key': user && user.data.business_secure_key,
                'apiconnection': 'appmobile',

            }
        })
        dispatch({ type: "products_brands", products_brands: res.data });
    }
}
export const events = (id, lang) => {

    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_API}/api/products/${id}/events`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'secure-business-key': user && user.data.business_secure_key,
                'apiconnection': 'appmobile',
                "Accept-Language": lang

            }
        })
        dispatch({ type: "events", events: res.data });
    }
}

export const login = (data) => {

    return async (dispatch) => {

        const res = await axios.post(`${ECOMMERCE_API}/api/representative/login`, data, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'Authorization': 'Basic Og==',

            }

        }).then((response) => {
            if (response.data && response.data.status === 1) {
                sessionStorage.setItem("userisLogin", true);
                sessionStorage.setItem("user", JSON.stringify(response.data));
            }
            return response
        }).catch(error => error.response);


        dispatch({ type: "login", login: res.data });
    }
}
export const ListUser = (page, key) => {

    return async (dispatch) => {

        const res = await axios.get(`${VODOGLOBAL_ACOUNTING_API}/api/business_users?page=${page}&pagination=true&paginate=12&keyword=${key}&user_type=1`, {
            headers: {
                'apikey': 'efe2db322a53',
                'secure-business-key': user && user.data.business_secure_key,
            }
        });

        dispatch({ type: "LISTUSER", users: res.data })
    };
};
// get currency test 
export const currency_categories_list = () => {
    return async (dispatch) => {
        const res = await axios.get(`${VODOGLOBAL_ACOUNTING_API}/api/pos/currency_categories_list`, {
            headers: {
                'apikey': 'efe2db322a53',
                'secure-business-key': user && user.data.business_secure_key,
                'Cookie': 'PHPSESSID=eb31b0779976e281abf45d10d15e26e7'
            }
        })
        dispatch({ type: "currency_categories_list", currency_categories_list: res.data })
    }
}
export const pos_order_detail = (order_id) => {
    return async (dispatch) => {
        const res = await axios.get(`${VODOGLOBAL_ACOUNTING_API}/api/pos/pos_order_detail?order_id=${order_id}`, {
            headers: {
                'apikey': 'efe2db322a53',
                'secure-business-key': user && user.data.business_secure_key,

            }
        })
        dispatch({ type: "pos_order_detail", pos_order_detail: res.data })
    }
}
export const sessions_list = (data) => {
    return async (dispatch) => {
        const res = await axios.post(`${ECOMMERCE_API}/api/pos/representative_sessions_list`, data, {
            headers: {
                'secure-business-key': user && user.data.business_secure_key,
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile'
            }
        })
        dispatch({ type: "sessions_list", sessions_list: res.data })
    }
}
export const add_customer = (data) => {
    return async (dispatch) => {
        const res = await axios.post(`${VODOGLOBAL_ACOUNTING_API}/api/add_customer`, data, {
            headers: {
                'secure-business-key': user && user.data.business_secure_key,
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile'
            }
        })
        dispatch({ type: "add_customer", add_customer: res.data })
    }
}
export const sessions_list_all = (data) => {
    return async (dispatch) => {
        const res = await axios.post(`${ECOMMERCE_API}/api/pos/representative_sessions_list`, data, {
            headers: {
                'secure-business-key': user && user.data.business_secure_key,
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile'
            }
        })
        dispatch({ type: "sessions_list_all", sessions_list_all: res.data })
    }
}
export const pos_All = (data) => {
    return async (dispatch) => {
        const res = await axios.post(`${ECOMMERCE_API}/api/pos/all`, data, {
            headers: {
                'secure-business-key': user && user.data.business_secure_key,
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
            }
        })
        dispatch({ type: "pos_All", pos_All: res.data })
    }
}
export const session_details = (data) => {
    return async (dispatch) => {
        const res = await axios.post(`${ECOMMERCE_API}/api/pos/session_details`, data, {
            headers: {
                'secure-business-key': user && user.data.business_secure_key,
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile'
            }
        })
        dispatch({ type: "session_details", session_details: res.data })
    }
}
export const create_credit_note_refund = (data) => {
    return async (dispatch) => {
        const res = await axios.post(`${VODOGLOBAL_ACOUNTING_API}/api/pos/create_credit_note`, data, {
            headers: {
                'secure-business-key': user && user.data.business_secure_key,
                'apikey': 'efe2db322a53',
                'apiconnection': 'appmobile'
            }
        })
        dispatch({ type: "create_credit_note_refund", create_credit_note_refund: res.data })
    }
}
// get nearest pos
export const nearest_pos = (user_id) => {
    return async (dispatch) => {
        const res = await axios.get(`${ECOMMERCE_API}/api/pos/nearest_pos?user_id=${user_id}`, {
            headers: {
                'secure-business-key': user && user.data.business_secure_key,
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
            }
        })
        dispatch({ type: "nearest_pos", nearest_pos: res.data })
    }
}
// ////////
export const session_payment_list = (session_id, page) => {
    return async (dispatch) => {
        const res = await axios.post(`${ECOMMERCE_API}/api/pos/session_payment_list`, { session_id: session_id, page }, {
            headers: {
                'secure-business-key': user && user.data.business_secure_key,
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile'
            }
        })
        dispatch({ type: "session_payment_list", session_payment_list: res.data })
    }
}
// cash accounts list
export const cash_accounts_list = (id) => {
    return async (dispatch) => {
        const res = await axios.get(`${VODOGLOBAL_ACOUNTING_API}/api/bank_cash_accounts?type=cash&user_id=${id}`, {
            headers: {
                'secure-business-key': user && user.data.business_secure_key,
                'apikey': 'efe2db322a53',

            }
        })
        dispatch({ type: "cash_accounts_list", cash_accounts_list: res.data })
    }
}
export const products_types = () => {
    return async (dispatch) => {
        const res = await axios.get(`${ECOMMERCE_API}/api/pos/products_types`, {
            headers: {
                'secure-business-key': user && user.data.business_secure_key,
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',

            }
        })
        dispatch({ type: "products_types", products_types: res.data })
    }
}
export const products_categories = () => {
    return async (dispatch) => {
        const res = await axios.get(`${ECOMMERCE_API}/api/pos/products_categories`, {
            headers: {
                'secure-business-key': user && user.data.business_secure_key,
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',

            }
        })
        dispatch({ type: "products_categories", products_categories: res.data })
    }
}
export const get_session_totals = (session_id) => {
    return async (dispatch) => {
        const res = await axios.post(`${ECOMMERCE_API}/api/pos/get_session_totals`, { session_id: session_id }, {
            headers: {
                'secure-business-key': user && user.data.business_secure_key,
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile'

            }
        })
        dispatch({ type: "get_session_totals", get_session_totals: res.data })
    }
}
// opening session
export const pos_session_orders_list = (data) => {
    return async (dispatch) => {
        const res = await axios.post(`${VODOGLOBAL_ACOUNTING_API}/api/pos/pos_session_orders_list`, data, {
            headers: {
                'apikey': 'efe2db322a53',
                'secure-business-key': user && user.data.business_secure_key
            }
        })
        dispatch({ type: "pos_session_orders_list", pos_session_orders_list: res.data })
    }
}
export const pos_session_orders_list_all = (data) => {
    return async (dispatch) => {
        const res = await axios.post(`${VODOGLOBAL_ACOUNTING_API}/api/pos/pos_session_orders_list`, data, {
            headers: {
                'apikey': 'efe2db322a53',
                'secure-business-key': user && user.data.business_secure_key
            }
        })
        dispatch({ type: "pos_session_orders_list_all", pos_session_orders_list_all: res.data })
    }
}
// 
export const open_session = (data) => {
    return async (dispatch) => {
        const res = await axios.post(`${ECOMMERCE_API}/api/pos/open_session`, data, {
            headers: {
                'secure-business-key': user && user.data.business_secure_key,
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile'
            }
        })
        dispatch({ type: "open_session", open_session: res.data })
    }
}
// 
export const close_session = (data) => {
    return async (dispatch) => {
        const res = await axios.post(`${ECOMMERCE_API}/api/pos/close_session`, data, {
            headers: {
                'secure-business-key': user && user.data.business_secure_key,
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
            }
        })
        dispatch({ type: "close_session", close_session: res.data })
    }
}

////
export const getCustom = (value) => {
    return (dispatch) => {
        dispatch({ type: "ADDCUSTOM", custom: value })
    }
}

export const add_sales_invoice = (data) => {
    return async (dispatch) => {

        const res = await axios.post(`${ECOMMERCE_API}/api/pos/add_sales_invoice`, data, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',

            }

        }).then((response) => {

            sessionStorage.setItem("testapi", JSON.stringify(response.data))

            return response.data
        })


        dispatch({ type: "add_sales_invoice", add_sales_invoice: res });
    }
}

export const reset_password = (data) => {
    return async (dispatch) => {
        const res = await axios.post(`${ECOMMERCE_API}/api/pos/reset_password`, data, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',

            }
        })
        dispatch({ type: "reset_password", reset_password: res.data });
    }
}
export const forgot_password = (data) => {

    return async (dispatch) => {

        const res = await axios.post(`${ECOMMERCE_API}/api/pos/forgot_password`, data, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',

            }

        })


        dispatch({ type: "forgot_password", forgot_password: res.data });
    }
}


export const Qunt = 'Qunt'
export const qunt = (value) => ({
    type: Qunt,
    payload: value
})

export const Orders_Suspension = (value) => {
    sessionStorage.setItem("orders_Suspension", JSON.stringify(value))
    const data = JSON.parse(sessionStorage.getItem("orders_Suspension"))
    return (dispatch) => {
        dispatch({ type: "orders_Suspension", payload: data })
    }
}
export const Select_Cart_Style = (value) => {
    value && sessionStorage.setItem("Select_Cart_Style", JSON.stringify(value))
    const data = JSON.parse(sessionStorage.getItem("Select_Cart_Style"))
    return (dispatch) => {
        dispatch({ type: "Select_Cart_Style", Select_Cart_Style: data })
    }
}
export const opt_session_style = (value) => {
    value && sessionStorage.setItem("opt_session_style", JSON.stringify(value))
    const data = JSON.parse(sessionStorage.getItem("opt_session_style"))
    return (dispatch) => {
        dispatch({ type: "opt_session_style", opt_session_style: data })
    }
}

export const AddCart = (value) => {
    sessionStorage.setItem("items", JSON.stringify(value))
    const data = JSON.parse(sessionStorage.getItem("items"))
    return (dispatch) => {
        dispatch({ type: "ADDITEM", payload: data })
    }
}
export const AddTabel = (value) => {
    sessionStorage.setItem("Tabels", JSON.stringify(value))
    const data = JSON.parse(sessionStorage.getItem("Tabels"))
    return (dispatch) => {
        dispatch({ type: "Tabels", payload: data })
    }
}
export const openSession = (value) => {
    return (dispatch) => {
        dispatch({ type: "openSession", openSession: value })
    }
}
export const ValueSearch = (value) => {
    return (dispatch) => {
        dispatch({ type: "ValueSearch", ValueSearch: value })
    }
}